<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <c-table
        ref="vendorTable"
        title="수급업체 첨부파일 목록"
        :columns="grid.columns"
        :data="grid.data"
        :filtering="false"
        :isFullScreen="false"
        :columnSetting="false"
        hideBottom
        selection="multiple"
        rowKey="mdmVendorAttachId"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="추가" v-if="editable && this.parentVendorCd" icon="add" @btnClicked="addAttach" />
            <c-btn label="삭제" v-if="editable && this.parentVendorCd && grid.data.length>0" icon="remove" @btnClicked="deleteAttach" />
            <c-btn 
              v-if="editable && this.parentVendorCd" 
              :isSubmit="isSaveAttach"
              :url="saveAttachUrl"
              :param="grid.data"
              mappingType="PUT"
              label="저장" 
              icon="save"
              @beforeAction="saveAttach"
              @btnCallback="saveAttachCallback" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'vendor-related-attach',
  props: {
    parentVendorCd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      grid: {
        data: [],
        columns: [],
      },
      editable: true,
      isSaveAttach: false,
      listAttachUrl: '',
      saveAttachUrl: '',
      deleteAttachUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  watch: {
    parentVendorCd() {
      if (this.parentVendorCd) {
        this.getList({ vendorCd: this.parentVendorCd })
      } else {
        this.grid.data = [];
      }
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listAttachUrl = selectConfig.mdm.cim.vendor.attach.list.url;
      this.saveAttachUrl = transactionConfig.mdm.cim.vendor.attach.save.url;
      this.deleteAttachUrl = transactionConfig.mdm.cim.vendor.attach.delete.url;
      // code setting
      this.$comm.getComboItems('VENDOR_ATTACH_TYPE_CD').then(_result => {
        this.grid.columns = [
          // {
          //   name: 'vendorCd',
          //   field: 'vendorCd',
          //   label: '업체',
          //   align: 'center',
          //   style: 'width:120px',
          //   sortable: false,
          //   required: true,
          //   type: 'vendor',
          // },
          {
            name: 'vendorAttachTypeCd',
            field: 'vendorAttachTypeCd',
            label: '첨부분류',
            align: 'center',
            style: 'width:160px',
            sortable: false,
            required: true,
            type: 'select',
            comboItems: _result,
          },
          {
            name: 'title',
            field: 'title',
            label: '제목',
            align: 'left',
            style: 'width:180px',
            sortable: false,
            required: true,
            type: 'text',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            // style: 'width:180px',
            sortable: false,
            type: 'textarea',
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            label: '등록일',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '첨부파일',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'VENDOR_ATTACH',
            keyText: 'mdmVendorAttachId',
            sortable: false,
          }
        ];
      });
      if (this.parentVendorCd) {
        this.getList({ vendorCd: this.parentVendorCd });
      }
    },
    getList(row) {
      this.$http.url = this.listAttachUrl;
      this.$http.type = 'GET';
      this.$http.param = {vendorCd: row.vendorCd}
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },
      () => {
      });
    },
    addAttach() {
      this.grid.data.splice(0, 0, {
        mdmVendorAttachId: uid(),  // 수급업체 첨부파일 일련번호
        vendorCd: this.parentVendorCd,  // 업체코드
        vendorAttachTypeCd: null,  // 업체별 첨부파일 구분 코드
        title: '',  // 업체 첨부파일 제목
        remark: '',  // 업체 첨부파일 비고
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C',
      })
    },
    deleteAttach() {
      let selectData = this.$refs[`vendorTable`].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteAttachUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs[`vendorTable`].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveAttach() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSaveAttach = !this.isSaveAttach
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveAttachCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('searchDetail');
    },
  }
};
</script>
